<template>
  <div>
    <h1>
      인증 완료후 확인 버튼을 눌러주세요.
    </h1>
      <div>
          <button @click="check()">
              확인
          </button>
      </div>
  </div>
</template>
<script>

import Vue from "vue";
import vueCryptojsMin from 'vue-cryptojs'
import Footer from "@/components/Footer";
import lang from "@/mixins/lang";
import { format } from "@/mixins/format";
import {mapState} from "vuex";
Footer

Vue.use(vueCryptojsMin)

export default {
  //components: { Footer },
  mixins: [format, lang],
  data(){
      return{
        encData : null,
        userInfo : null,
        accountId : null,
      }
  },
  created(){
    this.encData = localStorage.getItem("encData") ;
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.sEncodeData = this.$route.query.EncodeData;
  },
  mounted(){
      if(this.$route.query.currentLang != null) {
          this.currentLang = this.$route.query.currentLang;
      }
      this.workerType = this.$route.query.workerType;
      this.device = this.$route.query.device;
      this.accountId = this.$route.params.accountId;
  },
  computed: {
  ...mapState({
    secretKey : state => state.store.secretKey,
    iv: state => state.store.iv,
  }),
  currentLang: {
    get() {
      return this.$store.state.store.currentLang;
    },
    set(value) {
      this.$store.commit("store/langChange", value);
    },
  },
},
watch : {
  password() {
    if(this.password.length != 0){
      this.isPwShow = true;
    } else {
      this.isPwShow = false;
    }
  }
},
  methods : {
      check(){
        document.location.href = `/${this.accountId}/login` ;
      },
        
  },
};
</script>

<style>

</style>